import { WebPlayer } from "@tv4/avod-web-player";
import { storage } from "@tv4/avod-web-player-common";

import { clearLogin, getAccessToken, getLoggedInUserId } from "./credentials";
import {
  getLoginIframeSource,
  setupEventListenersForIframe,
  updateLoginState,
} from "./utils";

// @ts-expect-error it's just how it is
// eslint-disable-next-line no-underscore-dangle -- global
const player: WebPlayer = window.__player;

const loginInfo = document.querySelector<HTMLSpanElement>(
  "#login-info"
) as HTMLSpanElement;

const logoutButton = document.querySelector<HTMLButtonElement>(
  "#logout-button"
) as HTMLButtonElement;

document.addEventListener("DOMContentLoaded", () => {
  const accessToken = getAccessToken();
  const refreshToken = storage.getSessionData("refreshToken");
  if (accessToken && refreshToken && getLoggedInUserId()) {
    updateLoginState();
  }
  authIframe.src = getLoginIframeSource();
  setupEventListenersForIframe(authIframe);
});

const toggleLoginModalButton = document.querySelector<HTMLButtonElement>(
  "#toggle-login-modal-button"
) as HTMLButtonElement;
const authIframe = document.querySelector<HTMLIFrameElement>(
  "#auth-iframe"
) as HTMLIFrameElement;

toggleLoginModalButton.addEventListener("click", () => {
  const isHidden =
    window.getComputedStyle(authIframe).getPropertyValue("display") === "none";

  authIframe.style.display = isHidden ? "block" : "none";
});
logoutButton.addEventListener("click", () => {
  clearLogin();
  player.setOptions({ accessToken: undefined });
  logoutButton.style.display = "none";
  loginInfo.innerHTML = "Not logged in";
});
